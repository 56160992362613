/* 通用重置样式 */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

* {
    box-sizing: border-box;
    font-family: HarmonyOSHans-Medium, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif
}

/* 添加 100% 高度 */
html, body {
    width: 100%;
    height: 100%;
    -ms-overflow-style: none;  /* 适用于 Internet Explorer 和 Edge */
    scrollbar-width: none;     /* 适用于 Firefox */
}

::-webkit-scrollbar {
    display: none;
}


body {
    font-family: Arial, sans-serif;
    line-height: 1.5;
}
.bold-font {
    font-family: HarmonyOSHans-Regular, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

a {
    text-decoration: none;
    color: inherit;
}

img {
    max-width: 100%;
    border-style: none;
}

input, button, textarea, select {
    font: inherit;
    margin: 0;
    padding: 0;
}

button {
    overflow: visible;
}

button, input, select, textarea {
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    padding: 0;
    margin: 0;
}

form {
    display: block;
    margin: 0;
    padding: 0;
}

button, [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button;
}

[type="search"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
}

progress {
    vertical-align: baseline;
}

input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="tel"], input[type="number"], input[type="search"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

:-webkit-autofill {
    background-color: transparent;
    box-shadow: none;
}

*, *::before, *::after {
    box-sizing: border-box;
}